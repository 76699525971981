/* =================================
Helper classes
==================================== */
// Screen reader text

.srt {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

.push__left{
	left: -80%;
}
.push__up{
	top: 5px;
	opacity: .5;
}
.is__hidden{
	opacity: 0;
}
.is__visible{
	right: 0;
}
.is__sticky{
	height: 60px;
	background-color: $white;
	border-bottom: 1px solid darken($bg-nav, 5%);
}
.sticky{
	margin-top: 0;
}
.height{
	height: 300px;
}
.height-max{
	height: 400px;
}
.opa{
	display: block;
	opacity: 1;
}
%link{
	font-weight: $font-weight--medium;
	font-family: $font-family--primary;
	font-size: em(14px);
	text-align: center;
	text-transform: uppercase;
	line-height: 4em;
	letter-spacing: 1px;
}
%form__button{
	border: none;
	float: right;
	margin-bottom: em(22px);
	margin-top: em(22px);
	border-radius: 0.4em;
	box-shadow: 3px 6px 18px 0px rgba(0, 0, 0, 0.2);
	color: $white;
	background-color: $color-primary;
	text-transform: uppercase;
	font-size: 0.875em;
	font-weight: 400;
}
%card__image{
	border-radius: 5px;
	height: 400px;
	width: 100%;
	background-size: cover;
	background-position: center center;
	overflow: hidden;
	position: relative;
}
