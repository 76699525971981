/* =================================
Helper classes
==================================== */

%sidebar__text{
  font-weight: $font-weight--bold;
  font-family: "Dosis",sans-serif;
  font-size: 1.5em;
  text-transform: uppercase;
  letter-spacing: 1px;
}
