.header{
  position: fixed;
  padding: 0 5%;
  width: 100%;
  height: 100px;
  top: 0;
  left: 0;
  z-index: 1001;
  transition: all .4s cubic-bezier(0,.65,.63,.74);
}
