.card {
  flex: 1 100%;
  position: relative;
  height: 500px;
  margin-bottom: 2em;
  @include mq(large) {
    flex: 1 50%;
    padding: 0 1em;
  }
  @include e(info) {
    @include mq(large) {
      margin-right: 1em;
      width: auto;
    }
    padding: 0 1.5em;
    position: absolute;
    bottom: 15px;
    background-color: $white;
    height: 140px;
    box-sizing: inherit;
    border-radius: 0 0 5px 5px;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    @extend %transition;
  }
  @include e(label) {
    flex: 1 60%;
  }
  @include e(links) {
    flex: 1 40%;
    text-align: right;
  }
  @include e(heading) {
    margin: 0 0 0.5em 0;
    flex: 1 100%;
    font-size: em(28px);
    font-weight: $font-weight--medium;
  }
  @include e(subheading) {
    margin: 0;
    font-size: em(16px);
  }
  @include e(text) {
    font-size: em(12px);
    line-height: 1.8em;
    letter-spacing: 1px;
    font-weight: $font-weight--light;
    margin-bottom: 2em;
  }
  @include e(image) {
    @extend %card__image;

    @include m(dashboard) {
      @extend %card__image;
      // background-image: url("../img/p9-webb-app-dashboard.jpg");

      @include mq(large) {
        height: 350px;
      }
    }
    @include m(react) {
      @extend %card__image;
      background-image: url("../img/react.webp");

      @include mq(large) {
        height: 350px;
      }
    }
    @include m(performance) {
      @extend %card__image;
      background-image: url("../img/happyheart.png");

      @include mq(large) {
        height: 350px;
      }
    }
    @include m(accessibility) {
      @extend %card__image;
      // background-image: url("../img/p8-accessibility-refactor.jpg");

      @include mq(large) {
        height: 350px;
      }
    }
  }
  @include e(description) {
    // display: none;
    opacity: 0;
    @extend %transition;
  }
  @include e(icon) {
    width: 24px;
    height: 24px;
    margin-right: 1.1em;
  }
  @include e(link) {
    @include m(social) {
      color: $color-primary;
      position: relative;
      @extend %transition;
      &:hover {
        color: $pickled-bluewood;
      }
      &::before,
      &::after {
        position: absolute;
        left: 19%;
        opacity: 0;
        z-index: -100;
      }
      &:hover::before,
      &:hover::after,
      &:focus::before,
      &:focus::after {
        opacity: 1;
        z-index: 100;
      }
      &::before {
        border-style: solid;
        border-width: 1em 0.75em 0 0.75em;
        border-color: $pickled-bluewood transparent transparent transparent;
        bottom: 145%;
        margin-left: -0.5em;
        content: "";
      }
      &::after {
        background: $pickled-bluewood;
        border-radius: 5px;
        bottom: 225%;
        color: $white;
        width: 3.5em;
        padding: 0.5em;
        margin-left: -1em;
        content: attr(data-tip);
        text-align: center;
      }
    }
  }
}

.label {
  @include e(item) {
    margin-right: 5px;
    color: lighten($color-secondary, 20%);
    font-size: em(12px);
    font-weight: $font-weight--light;
    text-transform: uppercase;
    letter-spacing: 1px;
  }
}
