.logo {
  font-family: $font-family--primary;
  font-weight: $font-weight--bold;
  font-size: em(24px);
  letter-spacing: 1px;
  text-transform: uppercase;
  display: inline-block;
  padding-top: 0.4em;
  @extend %transition;
}
