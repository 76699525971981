/* =================================
Mixins
==================================== */

// BEM selectors

@mixin e($element) {
  &__#{$element} {
    @content;
  }
}
@mixin m($modifier) {
  &--#{$modifier} {
    @content;
  }
}

// Media queries

@mixin mq($break) {
  @if $break == "small" {
    @media only screen and (min-width: $brkpoint--sm) {
      @content;
    }
  }
  @else if $break == "medium" {
    @media only screen and (min-width: $brkpoint--md) {
      @content;
    }
  }
  @else if $break == "large" {
    @media only screen and (min-width: $brkpoint--lg) {
      @content;
    }
  }
  @else {
    @error "No value could be retrieved for `#{$break}`. "
  }
}
