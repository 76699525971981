/* =================================
Helper classes
==================================== */

// Clearfix

%clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}


// Center alignment

%center-align {
	text-align: center;
}


// Custom transition effects

%transition{
  transition: all .4s cubic-bezier(0,.65,.63,.74);
}
// %scroll{
//   transition: all .4s cubic-bezier(.06,.5,.6,.69);
// }


// Honeypot css class
.honey{
  display: none;
}
