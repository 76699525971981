.sidebar{
  @include mq(medium){
    flex: 1 20%;
  }
  @include e(content){
    position: relative;
    float: left;
    @include m(inverse){
      position: relative;
      float: left;
      @include mq(medium){
      float: right;
      }
    }
  }
  @include e(text){
    color: $color-primary;
    @extend %sidebar__text;
    @include m(inverse){
      color: $color-primary;
      @extend %sidebar__text;
      @include mq(medium){
        margin-bottom: 0;
        color: $white;
        position: absolute;
        text-align: center;
        top: 27px;
        left: 16px;
        font-size: 1em;
      }
    }
    @include mq(medium){
      position: absolute;
      text-align: center;
      top: 27px;
      left: 16px;
      font-size: 1em;
    }
  }
  @include e(icon){
    display: none;
    width: 80px;
    height: 80px;
    --primary-color: $color-primary;
    @include mq(medium){
      display: block;
    }
  }
}
