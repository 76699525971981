.mobile{
  @include e(menu){
    position: absolute;
    height: 35px;
    width: 35px;
    top: 1em;
    right: 5%;
    cursor: pointer;
    transition: all .4s cubic-bezier(0,.65,.63,.74);
    display: block;
    svg {
      width: 35px;
      height: 35px;
    }
    @include mq(medium){
      display: none;
    }
  }
}
