.form{
  flex: 1 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  @include mq(medium){
    flex-direction: row;
    width: 80%;
  }
  @include e(fieldset){
    padding-top: 2.5em;
    padding-right: 2em;
    border:none;
    position: relative;
    width: 100%;
    margin: auto;
    @include m(button){
      flex: 1 100%;
      margin-top: .5em;
    }
  }
  @include e(label){
    top: 30px;
    font-size: 1em;
    position: absolute;
    @extend %transition;
  }
  @include e(input){
    color:$pickled-bluewood;
    border-bottom: 1px solid #d5d5d5;
    width: 100%;
    padding-right: em(16px);
    padding-bottom: em(16px);
    font-weight: $font-weight--light;
    @extend %transition;
    &:focus{
      border-bottom-color: $aqua-island;
    }
    @include m(textarea){
      color:$pickled-bluewood;
      font-weight: $font-weight--light;
      border-bottom: 1px solid #d5d5d5;
      width: 100%;
      @extend %transition;
      height: 220px;
      &:focus{
        border-bottom-color: $aqua-island;
      }
    }
  }
  @include e(button){
    @extend %form__button;
    padding: 15px 30px;
    @extend %transition;
    &:hover{
      opacity: .9;
      box-shadow: 1px 2px 5px 0px rgba(0, 0, 0, 0.2);
    }
    @include m(disabled){
      @extend %form__button;
      padding: 2px 30px 1px 29px;
      opacity: .4;
    }
  }
  @include e(left){
    flex: 1 100%;
    @include mq(medium){
      flex: 1 35%;
    }

  }
  @include e(right){
    flex: 1 100%;
    @include mq(medium){
      flex: 1 65%;
    }
  }
  @include e(icon){
    width: 40px;
    height: 40px;
    fill: $aqua-island;
  }

}
