/* =================================
Base Element Styles
==================================== */
* {
  box-sizing: border-box;
}

body {
  position: relative;
  min-height: 100%;
  width: 100%;
  color: $color-primary;
  font-size: $base__font-size;
  font-family: $font-family--secondary;
  line-height: $base__line;
  background-color: $white;
  left: 0;
  @extend %transition;
}

// Text
h1, h2, h3 {
  font-family: $font-family--primary;
  font-weight: $font-weight--bold;
  color: $color-primary;
}

a {
  text-decoration: none;
  color: $color-primary;
}
a:focus{
  outline: none;
}

p {
  font-family: $font-family--secondary;
  font-size: em(18px);
  line-height: $base__line;
  color: $color-secondary;
}

// Lists

ul,
li {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

// Images

img {

}

// Form elements
fieldset, textarea, input{
  border: none;
}
textarea {
  resize: none;
}

// Button

input, textarea, button {
  outline: 0;
}
