.work{
  @extend %clearfix;
  padding: 100px 5% 0 5%;
  padding-top: 100px;
  background-color: $bg-nav;
  @include mq(medium){
    display: flex;
    flex-direction: row-reverse;
  }
  @include e(content){
    margin-top: 1em;
    @include mq(medium){  
      display: flex;
      flex-wrap: wrap;
      margin-top: 0;
      flex: 1 80%;
    }
  }
}
