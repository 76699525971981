.contact{
  @extend %clearfix;
  padding: 100px 5% 0 5%;
  @include e(title){
    flex: 1 100%;
    text-align: center;
    letter-spacing: 2px;
    font-size: em(32px);
    margin-bottom: 0;
    @include mq(medium){
      margin-top: 0;
      font-size: em(48px);
    }
  }
  @include e(text){
    flex: 1 100%;
    text-align: center;
    font-size: em(20px);
    line-height: 1.5em;
    font-weight: $font-weight--light;
    margin-top: 0.8em;
  }
  @include e(check){
    text-align: center;
  }
}
