.footer{
  margin-top: 100px;
  bottom: 0;
  display: flex;
  width: 100%;
  height: 300px;
  text-align: center;
  flex-direction: column;
  background-color: $color-link;
  @include mq(medium){
    flex-direction: row;
    justify-content: space-around;
    text-align: left;
    height: 175px;
    vertical-align: middle;
  }
  @include e(heading){
    color: #ffd700;
    text-transform: uppercase;
    letter-spacing: 2px;
    font-size: em(16px);
    font-weight: $font-weight--medium;
    margin-bottom: .7em;
    @include m(author){
      color: #ffd700;
      text-transform: uppercase;
      letter-spacing: 2px;
      font-size: em(16px);
      font-weight: $font-weight--medium;
      margin-bottom: .7em;
      display: none;
      @include mq(medium){
        display: block;
      }
    }
  }
  @include e(label){
    font-size: em(14px);
    color: #ffd700;
    letter-spacing: 1px;
    padding-right: em(16px);
    font-weight: $font-weight--light;
  }
  @include e(link){
    font-size: em(14px);
    color:white;
    font-weight: $font-weight--light;
    @extend %transition;
    @include m(social){
      color:white;
      @extend %transition;
      &:hover{
        color: $color-primary;
      }
    }
  }
  @include e(icon){
    width: 24px;
    height: 24px;
    margin-right: 1.1em;
  }
  @include e(info){
    margin: 1em 0;
    @include mq(medium){
      margin: auto;
    }
  }
}
